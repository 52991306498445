import { Steps as AntdSteps, Col, Row } from "antd";
import { useEffect, useState } from "react";
import Button from "../../components/custom/input/button/Button";
import VideoComponent from "../../components/custom/video/VideoComponent";
import useTemplates from "../../hooks/useTemplates";
import { capitalizeFirstLetter } from "../utils/commonUtils";

export default function OnboardingFinishModalContent({ serviceName, appProperties }) {
  const onboardingComponents = appProperties?.providerCommonObj?.onBoardingFlow?.getFinishModalComponent(appProperties);
  const leftServiceName = appProperties?.leftServiceClientDetails?.name;
  return (
    <OnboardingModalContent videoGallery={<OnboardingModalContent.VideoGallery gallery={onboardingComponents?.getGallery(leftServiceName)} />}>
      <OnboardingModalContent.Banner serviceName={serviceName} />
      <OnboardingModalContent.Steps
        steps={onboardingComponents?.steps}
        calculateStep={onboardingComponents?.calculateStep}
        appProperties={appProperties}
      />
      <OnboardingModalContent.Description leftServiceName={leftServiceName} rightServiceName={serviceName} />
      <OnboardingModalContent.GotoButton
        hyperLink={appProperties.domain + appProperties?.controller?.redirectPath}
        leftServiceName={leftServiceName}
      />
    </OnboardingModalContent>
  );
}

function OnboardingModalContent({ children, videoGallery }) {
  return (
    <Row align={"middle"}>
      <Col span={videoGallery ? 12 : 24}>
        <div className="m-4 me-0 p-4 pe-0 d-grid gap-3 h-100">{children}</div>
      </Col>
      {videoGallery && (
        <Col span={12}>
          <div className="h-100">
            <div className="banners-sprite hs-onboarding-modal-bg">
              <div className="p-4 h-100 d-flex align-items-center py-5">{videoGallery}</div>
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
}

OnboardingModalContent.Banner = Banner;
OnboardingModalContent.Steps = Steps;
OnboardingModalContent.Description = Description;
OnboardingModalContent.VideoGallery = VideoGallery;
OnboardingModalContent.GotoButton = GotoButton;

function GotoButton({ hyperLink, leftServiceName }) {
  return (
    <Button size="xl" buttonClassName={"hs-w-fit rounded-pill fw-bold"} onClick={() => window.open(hyperLink, "_blank")}>
      Go to {capitalizeFirstLetter(leftServiceName)}
    </Button>
  );
}

function VideoGallery({ gallery }) {
  return (
    <div className="d-grid gap-2 my-4">
      <span className="text-white mx-auto">Video Gallery</span>
      {gallery && <VideoComponent videoObj={gallery} gap="3" />}
    </div>
  );
}

function Steps({ size = "small", direction = "vertical", calculateStep, steps }) {
  const [step, setStep] = useState();
  const { fetchSavedTemplates } = useTemplates();
  useEffect(() => {
    if (calculateStep) {
      calculateStep(fetchSavedTemplates).then((step) => {
        setStep(step);
      });
    }
  }, [fetchSavedTemplates]);

  return <AntdSteps responsive rootClassName="hs-steps-modal" direction={direction} size={size} status="process" current={step} items={steps} />;
}

function Description({ leftServiceName, rightServiceName }) {
  return (
    <>{`Once all these steps are finished, you can proceed to your ${capitalizeFirstLetter(
      leftServiceName
    )} account to start messaging via ${capitalizeFirstLetter(rightServiceName)}`}</>
  );
}

function Banner({ serviceName }) {
  return (
    <div className="d-grid gap-3">
      <div className={`banners-sprite ${serviceName}-banner`} />
      {"Please ensure that you have completed the following steps:"}
    </div>
  );
}
